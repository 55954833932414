import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { SiteUrl ,UploadUrl} from "../Define/Define";
const Welcome = () => {
  const [companyDetails, setcompanyDetails] = useState({});

  useEffect(() => {
    const companyDetails = async () => {
      try {
        const res = await axios.post(`${SiteUrl}server/companyDetails.php`, {
          pid: "",
        });
        const response = res.data[0];
        // console.log(response);
        setcompanyDetails(response);
      } catch (error) {
        console.log(error);
      }
    };

    companyDetails();
  }, []);
  return (
    <>
      <div id="appCapsule" className="pt-0">
        <div className="section bg-csblue">
          <div className="splash-page">
            <div className="mb-2">
              <img
                src="./assets/img/wel2.png"
                alt="welcome"
                className="img-fluid mb-1 remove-background"
              />
            </div>
          </div>
        </div>
        <div className="section">
          <h2 className="font-16">
            Effortlessly Manage, Monitor, and Elevate Your Projects
          </h2>
          <p className="font-14">
            Streamline project management, monitor progress, and optimize tasks
            effortlessly. Stay on top with ease!
          </p>
          <div className="form-button-group border-none bg-transparent">
            <Link to="/login" className="w-100">
              <button
                type="button"
                className="btn btn-primary btn-block btn-lg me-1"
              >
                Sign In
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
