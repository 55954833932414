import React, { useState, useEffect } from "react";
import axios from "axios";
import { SiteUrl } from "../Define/Define";

const FetchTask = ({ pid }) => {
  const [taskStatus, setTaskStatus] = useState({
    total: 0,
    complete: 0,
    pending: 0,
    partially: 0,
    pid: "",
  });

  useEffect(() => {
    const fetchTaskStatus = async () => {
      try {
        const resTak = await axios.post(`${SiteUrl}server/fetchTask.php`, {
          ProjectId: pid,
        });
        setTaskStatus(resTak.data);
      } catch (error) {
        setTaskStatus({
          total: 0,
          complete: 0,
          pending: 0,
          partially: 0,
          pid: "",
        });
      }
    };

    fetchTaskStatus();
  }, [pid]);
  let partiallyCompletedPercentage =
    (taskStatus.partially / taskStatus.total) * 100;
  let completedPercentage = (taskStatus.complete / taskStatus.total) * 100;
  let TotalPercent = 0;
  if (pid == taskStatus.pid) {
    TotalPercent = (partiallyCompletedPercentage + completedPercentage).toFixed(
      0
    );
  }
  return (
    <>
      <div className="w-100 d-flex flex-row justify-content-between align-items-center">
        
        <div className="progress" style={{ width: "100%", }}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${TotalPercent}%` }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <div className="text-center" style={{ marginLeft: '6px' }}>
          <span className="badge badge-info px-2">{TotalPercent > 0 ? TotalPercent : 0}%</span>
        </div>

      </div>
    </>
  );
};

export default FetchTask;
