import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { SiteUrl } from "../Define/Define";

const Payment = () => {
  const [walletAmount, setwalletAmount] = useState(0);
  const [Txn, setTxn] = useState([]);
  const cuid = localStorage.getItem("user:token");

  useEffect(() => {
    const fetchWallet = async () => {
      try {
        const response = await axios.post(`${SiteUrl}server/fetchWallet.php`, {
          cuid: cuid
        });
        setwalletAmount(response.data);
      } catch (error) {
        setwalletAmount(0);
      }
    };
    fetchWallet();
  }, [cuid]);

  useEffect(() => {
    const fetchTxn = async () => {
      try {
        const response = await axios.post(
          `${SiteUrl}server/fetchTxn.php`,
          {
            cuid: cuid,
            limit: 6,
          }
        );
        setTxn(response.data);
      } catch (error) {
        setTxn([]);
      }
    };
    fetchTxn();
  }, [cuid]);
  const listItemStyle = {
    content: "",
    display: "block",
    position: "absolute",
    left: "0",
    right: "0",
    bottom: "0",
    height: "1px",
    background: "transparent",
  };
  const navigate = useNavigate();
  // console.log(Txn);
  const goToView = (invidP) => {
    navigate("/transaction-detail", { state: { txn: invidP } });
  };
  return (
    <>
      <div id="appCapsule">
        <div className="section wallet-card-section1 pt-2">
          <div className="wallet-card py-1 px-3">
            <div className="d-flex align-items-center">
              <div style={{ fontSize: '28px', color: '#FFC107', backgroundColor: '#f7efd9', marginRight: '10px', padding: '6px 8px', borderRadius: '4px' }}>
                <ion-icon name="wallet"></ion-icon>
              </div>
              <div className="d-flex flex-column">
                <span className="title">Wallet</span>
                <h1 className="" style={{ margin: '0px' }}>&#8377; {walletAmount}</h1>
              </div>
              <div className="right"></div>
            </div>
          </div>
          <div className="section full">
            <div className="row mt-2">
              <div className="col-6">
                <div className="stat-box">
                  <div className="d-flex align-items-center">
                    <div className="" style={{ marginRight: '10px', backgroundColor: '#FFF3C2', padding: '8px', borderRadius: '4px' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width='26px'><path fill="#e58810" d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160l0 8c0 13.3 10.7 24 24 24l400 0c13.3 0 24-10.7 24-24l0-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224l-64 0 0 196.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512l448 0c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1L448 224l-64 0 0 192-40 0 0-192-64 0 0 192-48 0 0-192-64 0 0 192-40 0 0-192zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" /></svg>
                    </div>
                    <div>
                      <div className="title">Transaction</div>
                      <div className="value font-adjuzzst text-success" style={{ margin: '0px' }}>
                        &#8377; 0
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="stat-box">
                  <div className="d-flex align-items-center">
                    <div style={{ marginRight: '10px', backgroundColor: '#D0F3E2', padding: '8px', color: '5de29f', borderRadius: '4px' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width='26px'>
                        <path fill="#5de29f" d="M96 96l0 224c0 35.3 28.7 64 64 64l416 0c35.3 0 64-28.7 64-64l0-224c0-35.3-28.7-64-64-64L160 32c-35.3 0-64 28.7-64 64zm64 160c35.3 0 64 28.7 64 64l-64 0 0-64zM224 96c0 35.3-28.7 64-64 64l0-64 64 0zM576 256l0 64-64 0c0-35.3 28.7-64 64-64zM512 96l64 0 0 64c-35.3 0-64-28.7-64-64zM288 208a80 80 0 1 1 160 0 80 80 0 1 1 -160 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120L0 360c0 66.3 53.7 120 120 120l400 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-400 0c-39.8 0-72-32.2-72-72l0-240z" /></svg>
                    </div>
                    <div>
                      <div className="title">Earned</div>
                      <div className="value font-adjuzzst text-success" style={{ margin: '0px' }}>
                        &#8377; 0
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section full mb-3">
            <style>
              {`
          .listview > li:after {
            ${Object.entries(listItemStyle)
                  .map(([key, value]) => `${key}: ${value};`)
                  .join("\n")}
          }
        `}
            </style>
            <div className="wallet-card mt-2 py-2 px-1">
              <ul className="listview px-0 border-0 image-listview">
                <li>
                  <Link to="/invoice" className="item px-2 py-1">
                    <div className="icon-box border font-15 fw-6 rounded-10">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="18px">
                    <path fill="orange" d="M14 2.2C22.5-1.7 32.5-.3 39.6 5.8L80 40.4 120.4 5.8c9-7.7 22.3-7.7 31.2 0L192 40.4 232.4 5.8c9-7.7 22.3-7.7 31.2 0L304 40.4 344.4 5.8c7.1-6.1 17.1-7.5 25.6-3.6s14 12.4 14 21.8l0 464c0 9.4-5.5 17.9-14 21.8s-18.5 2.5-25.6-3.6L304 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L192 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L80 471.6 39.6 506.2c-7.1 6.1-17.1 7.5-25.6 3.6S0 497.4 0 488L0 24C0 14.6 5.5 6.1 14 2.2zM96 144c-8.8 0-16 7.2-16 16s7.2 16 16 16l192 0c8.8 0 16-7.2 16-16s-7.2-16-16-16L96 144zM80 352c0 8.8 7.2 16 16 16l192 0c8.8 0 16-7.2 16-16s-7.2-16-16-16L96 336c-8.8 0-16 7.2-16 16zM96 240c-8.8 0-16 7.2-16 16s7.2 16 16 16l192 0c8.8 0 16-7.2 16-16s-7.2-16-16-16L96 240z"/></svg>
                    </div>
                    <div className="in">
                      <div className="text-dark fw-5 font-14">Invoice</div>
                    </div>
                    <div>
                      <ion-icon
                        name="chevron-forward-outline"
                        className="fw-5 text-muted"
                      ></ion-icon>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="listview-title mt-2 d-flex justify-content-between align-items-center">
              <span>Transaction</span>
              <Link to={'/transaction-list'}>
                View All
              </Link>
            </div>
            <ul className="listview bg-transparent image-listview">
              {Txn &&
                Txn.length > 0 &&
                Txn.map((data, index) => {
                  return (
                    <li
                      onClick={() => goToView(data.txnID)}
                      key={index}
                      className="rounded-10 bg-white mt-1 flex-column"
                    >
                      <div
                        className="item flex-column"
                        style={{ minHeight: "30px" }}
                      >
                        <h5
                          style={{ wordBreak: "break-all" }}
                          className="fw-6 mb-1 font-14"
                        >
                          {data.txnID}
                        </h5>
                        <div className="in">
                          <div>
                            <p className="mb-0 text-muted font-12 fw-5">
                              {data.paidDate}
                            </p>
                          </div>

                          <span className="text-success font-12 fw-7">
                            &#8377; {data.amount}
                          </span>
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div >
    </>
  );
};

export default Payment;
