import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";
import he from "he";
import { SiteUrl, UploadUrl } from "../Define/Define";
import FetchTask from "./fetchTask";
import RowList from "./RowList";

const ProjectDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state.id;
  const [projectData, setProjectData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [toast, setToast] = useState({ visible: false, doc: "" });
  const [getInvoiceSum, setgetInvoiceSum] = useState({});
  const [preloader, setPreloader] = useState(true);

  useEffect(() => {
    const fetchProjectDetail = async () => {
      try {
        const res = await axios.post(
          `${SiteUrl}server/fetchProjectDetail.php`,
          { pid: id }
        );
        const response = res.data[0];
        setProjectData(response);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchProjectDetail();
  }, []);

   useEffect(() => {
     const getInvoiceSum = async () => {
       try {
         const res = await axios.post(
           `${SiteUrl}server/getInvoiceSumProject.php`,
           {
             prjid: projectData.prjid,
           }
         );
         const response = res.data;
         setgetInvoiceSum(response);
       } catch (error) {
         console.log(error);
       }
     };

     getInvoiceSum();
   }, [projectData.prjid]);
  const handleClose = () => {
    setToast((prevToast) => ({ ...prevToast, visible: false }));
  };

  

 
const downloadPdf = (url, fileName) => {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
const decodedDescription = projectData.pDescription
  ? he.decode(projectData.pDescription)
  : "";
  const docsArray = projectData.docs ? projectData.docs.split(",") : [];

const goToView = (pidR) => {
   navigate("/contract", { state: { pid: pidR } });
 };
 useEffect(()=>{
  setTimeout(()=>{setPreloader(false);},1200);
 },[]);
  return (
    <>
      {preloader && (
        <div
          id="loader"
          className="d-flex justify-content-center align-items-center bg-transparent"
          style={{ display: "block" }}
        >
          <div className="spinner-border text-dark" role="status"></div>
        </div>
      )}
      <div className="appHeader bg-primary text-light">
        <div className="left">
          <span className="headerButton goBack"  style={{cursor: "pointer"}}>
            <ion-icon
              name="chevron-back-outline"
              role="img"
              className="md hydrated"
              aria-label="chevron back outline"
            ></ion-icon>
          </span>
        </div>
        <div className="pageTitle">Project Detail</div>
        <div className="right">
          <ion-icon
            name="document-text-outline"
            onClick={() => goToView(id)}
          ></ion-icon>
        </div>
      </div>
      <div id="appCapsule">
        <div className="section px-2 pt-2">
          <div className="wallet-card py-2">
            <div className="section-title text-dark fw-5 font-15">
              {projectData.ptitle}
            </div>
            <div className="section-title p-0 mt-0">
              <FetchTask pid={id} />
            </div>
          </div>
        </div>
        <div className="section full px-2 mt-2">
          <div className="wallet-card py-2">
            <RowList title="Project ID" desc={projectData.prjid} />
            <RowList
              title="Project Cost"
              desc={React.createElement("span", {
                dangerouslySetInnerHTML: {
                  __html: `&#8377; ${projectData.pAmount}`,
                },
              })}
            />

            <RowList title="Start Date" desc={projectData.startDate} />
            <RowList title="End Date" desc={projectData.endDate} />
            <RowList
              title="Paid Amount"
              desc={React.createElement("span", {
                dangerouslySetInnerHTML: {
                  __html: `&#8377; ${getInvoiceSum.paid}`,
                },
              })}
            />
            <RowList
              title="Due Amount"
              desc={React.createElement("span", {
                dangerouslySetInnerHTML: {
                  __html: `&#8377; ${getInvoiceSum.unpaid}`,
                },
              })}
            />
            <RowList
              title="Status"
              desc={React.createElement("span", {
                dangerouslySetInnerHTML: {
                  __html: `${getInvoiceSum.status}`,
                },
              })}
            />
          </div>
        </div>
        <div className="section full px-2 mt-2">
          <div className="wallet-card py-2 px-0">
            <div className="section-title font-15">Document : </div>
            {docsArray.map((doc, index) => {
              if (doc !== "") {
                return (
                  <button
                    key={index}
                    style={{
                      height: "auto",
                      background: "transparent",
                      position: "relative",
                    }}
                    onClick={() =>
                        downloadPdf(
                          `${UploadUrl}client/${projectData.cltuid}/${doc}`,
                          `${doc}`
                        )
                      }
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#DialogIconedSuccess"
                  >
                    <span
                      className="rounded-circle"
                      style={{
                        fontWeight: "900",
                        width: "20px",
                        height: "20px",
                        position: "absolute",
                        bottom: "10px",
                        left: "20%",
                        display: "grid",
                        placeContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <ion-icon
                        className="font-10"
                        name="cloud-download-outline"
                      ></ion-icon>
                    </span>
                    <img
                      src="../assets/img/pdf.png"
                      alt="pdf"
                      className="imaged w64"
                    />
                  </button>
                );
              } else {
                return null;
              }
            })}
          </div>
          {toast.visible && (
            <div
              className="modal fade dialogbox show"
              id="DialogIconedSuccess"
              data-bs-backdrop="static"
              tabIndex="-1"
              style={{ background: "rgba(0,0,0,.3)", display: "block" }}
              aria-modal="true"
              role="dialog"
            >
              <div className="modal-dialog" role="document">
                <div
                  className="modal-content"
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    height: "100%",
                  }}
                >
                  <div className="modal-body">
                    <div className="container-iframe"></div>
                  </div>
                  <div className="modal-footer" onClick={handleClose}>
                    <div className="btn-inline">
                      <a href="#" className="btn" data-bs-dismiss="modal">
                        CLOSE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="section full px-2 mt-2">
          <div className="wallet-card py-2 px-3">
            <div className="section-title font-15 pl-0">Description : </div>
            {parse(decodedDescription)}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectDetail;
