import React, { useState, useEffect } from "react";
import MyCarousel from './Carousel';
import { Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";
import he from "he";
import { SiteUrl } from "../Define/Define";
import FetchTask from "./fetchTask";

import Slider from 'react-slick';

// Slick Css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Home = () => {

  const navigate = useNavigate();

  const [projects, setProjects] = useState([]);
  const [Notice, setNotice] = useState([]);
  const cuid = localStorage.getItem("user:token");

  useEffect(() => {
    fetchProject();
  }, []);
  useEffect(() => {
    const fetchNotice = async () => {
      try {
        const response = await axios.post(`${SiteUrl}server/fetchNotice.php`, {
          noticeType: 'Client',
        });
        setNotice(response.data);
      } catch (error) {
        setNotice([]);
      }
    };
    fetchNotice();
  }, []);
  // console.log(Notice);
  const fetchProject = async () => {
    try {
      const response = await axios.post(`${SiteUrl}server/fetchProject.php`, {
        cuid: cuid,
      });
      setProjects(response.data);
    } catch (error) {
      setProjects([]);
    }
  };
  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 0 && currentHour < 12) {
      return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      return "Good Afternoon";
    } else {
      return "Good Night";
    }
  };
  const getIcon = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 0 && currentHour < 12) {
      return <ion-icon name="partly-sunny-outline"></ion-icon>;
    } else if (currentHour >= 12 && currentHour < 18) {
      return <ion-icon name="sunny-outline"></ion-icon>;
    } else {
      return <ion-icon name="moon-outline"></ion-icon>;
    }
  };
  const goToView = (invidP) => {
    navigate("/project-detail", { state: { id: invidP } });
  };
  const goToViewNotice = (ntidP) => {
    navigate("/notice-detail", { state: { id: ntidP } });
  };

  const listItemStyle = {
    content: "",
    display: "block",
    position: "absolute",
    left: "0",
    right: "0",
    bottom: "0",
    height: "1px",
    background: "transparent",
  };

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

    ],
  };

  return (
    <>
      <style>
        {`
          .listview > li:after {
            ${Object.entries(listItemStyle)
            .map(([key, value]) => `${key}: ${value};`)
            .join("\n")}
          }
        `}
      </style>
      <div id="appCapsule">

        <div className="py-2 px-4" style={{ backgroundColor: '#328FF1', marginTop: '6px' }}>
          <div className="d-flex justify-content-between">
            <div style={{ color: 'white' }}>
              <p className="m-0" style={{ fontSize: '30px', fontWeight: '500' }}>{getGreeting()}</p>
              <p className="" style={{ fontSize: '18px', marginBottom: '0px', marginTop: '6px' }}>{localStorage.getItem("user:name")}</p>
            </div>

            <div style={{ color: 'white', fontSize: '50px' }}>
              {getIcon()}
            </div>
          </div>
        </div>

        <div className="section">
          {/* <MyCarousel></MyCarousel> */}
        </div>


        <div className="section full pb-5 mt-4" style={{ overflow: 'hidden' }}>
          <div className="section-title" style={{ fontSize: '16px' }}>
            Project
          </div>

          {projects.length > 0 ? (

            <Slider {...settings}>
              {projects.map((project, index) => {
                return (
                  <div className="p-1 pb-4" key={index}>
                    <div className="item rounded p-2 shadow" onClick={() => goToView(project.id)}>
                      <div
                        style={{
                          display: 'inline-block',
                          fontSize: '28px',
                          backgroundColor: '#E7F4D6',
                          padding: '6px 8px',
                          borderRadius: '4px',
                          color: '#6A8A00',
                        }}
                      >
                        <ion-icon name="business-outline"></ion-icon>
                      </div>

                      <div className="text">
                        {project.ptitle.length > 24
                          ? project.ptitle.substring(0, 24) + '...'
                          : project.ptitle}
                      </div>

                      <div style={{ width: '100%' }}>
                        <FetchTask pid={project.id} />
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          ) : (
            <div className="alert alert-warning m-2">No Projects Found!</div>
          )}
        </div>


        <div className="section mb-3 mt-3">
          <div className="section-title font-16 mt-2 d-flex justify-content-between align-items-center">
            <span>Notice</span>
            <Link to={"/notice-list"}>View All</Link>
          </div>
          <ul className="listview rounded-10 image-listview">
            {Notice.length > 0 ? (
              Notice.map((data) => {
                let title = data.notice ? he.decode(data.notice) : "";
                return (
                  <li className="py-1 border-b" key={data.id}>
                    <div
                      onClick={() => goToViewNotice(data.id)}
                      className="item px-2 py-1 pr-1"
                    >
                      <div className="icon-box bg-warning border font-16 fw-6 rounded-10">
                        <ion-icon
                          name="notifications-outline"
                          className="text-white"
                        ></ion-icon>
                      </div>
                      <div className="in">
                        <div className="w-100">
                          <div className="project-title text-dark fw-5 font-14">
                            {title.length > 35
                              ? parse(title.substring(0, 32)) + "..."
                              : parse(title)}
                          </div>
                          <span className="font-9">{data.date}</span>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <div className="alert alert-warning">No Notice Found!</div>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Home